/*
 * @Autor: syq
 * @Date: 2021-08-03 11:22:07
 */
/**
 * 公共部分
 */
class Enum extends Map {
  rows(key = "value", value = "text") {
    const map = this;
    return Array.from(map).map((item) => ({
      [key]: item[0],
      [value]: item[1],
      valueCode: item[0],
    }));
  }
}

// 合作方状态
export const minemessage_status = new Enum([
  [10, "启用"],
  [20, "禁用"],
]);
