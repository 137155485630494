<template>
  <div>
    <div class="title">个人信息</div>
    <!-- <div class="header">用户信息</div> -->
    <div class="updata">
      <van-cell title="客户名称" :value="informationData.customerInfo.name" />
      <!-- <van-cell title="售达方编码" :value="informationData.code" /> -->
      <!-- <van-cell title="售达方名称" :value="informationData.username" /> -->
      <van-cell title="公司名称" :value="informationData.companyInfo.name" />
      <van-cell title="法人" :value="informationData.companyInfo.legalRepresentName" />
      <van-cell title="销售编号" :value="informationData.customerInfo.refObjCode" />
      <van-cell title="联系电话" :value="informationData.phone" />
      <van-cell title="邮箱" :value="informationData.email" />
      <van-cell title="地址" :value="informationData.companyInfo.address" />
      <!-- <van-cell title="账号状态" :value="informationData.status" /> -->
      <!-- <van-cell
        title="星级信息"
        :value="informationData.companyInfo.starLevel"
      /> -->
    </div>
    <br />
    <br />
    <!-- <div class="header">组织信息</div> -->
    <!-- <div class="updata">
      <van-cell
        title="销售组织编码"
        :value="informationData.companyInfo.extStrField03"
      />
      <van-cell
        title="销售区域编码"
        :value="informationData.companyInfo.salesRegionCode"
      />
      <van-cell
        title="销售部门编码"
        :value="informationData.companyInfo.salesDepartmentCode"
      />
      <van-cell
        title="销售片区编码"
        :value="informationData.companyInfo.salesAreaCode"
      />
      <van-cell
        title="价格组编码"
        :value="informationData.companyInfo.extStrField02"
      />
      <van-cell
        title="母账号编码"
        :value="informationData.companyInfo.extStrField04"
      />
    </div> -->
    <br />
    <br />

    <!-- <div class="header">经销商信息</div>
    <div class="updata">
      <van-cell
        title="经销商法人"
        :value="informationData.companyInfo.legalRepresentName"
      />
      <van-cell
        title="经销商地址"
        :value="informationData.companyInfo.address"
      />
      <van-cell
        title="经销商类型"
        :value="houseHoldType[informationData.companyInfo.householdCategory]"
      />
      <van-cell
        title="经销商经营范围"
        :value="informationData.companyInfo.businessScope"
      />
      <van-cell
        title="运输区域编码"
        :value="informationData.companyInfo.extStrField05"
      />
      <van-cell
        title="合作伙伴编码"
        :value="informationData.companyInfo.code"
      />
      <van-cell
        title="是否支持自提"
        :value="informationData.companyInfo.extStrField06 ? '是' : '否'"
      />
      <van-cell title="城市" :value="informationData.companyInfo.cityName" />
    </div> -->
    <div class="quit">
      <button @click="back()" class="btn">返回</button>
    </div>
    <van-loading size="24px" v-show="minemessageloading" vertical>加载中...</van-loading>
  </div>
</template>

<script>
import { getAccount } from "./service";
import { minemessage_status } from "../../common/menu";

let houseHoldType = {
  10: "直营公司",
  20: "定点零售户",
  30: "机场",
};

export default {
  data() {
    return {
      text: "",
      tel: "",
      minemessageloading:false,
      username: "",
      informationData: {},
      houseHoldType: houseHoldType,
    };
  },
  mounted() {
    this.minemessageloading = true
    getAccount().then((res) => {
      this.minemessageloading = false
      this.informationData = res.data;
      this.informationData.status = minemessage_status.get(
        this.informationData.status
      );
    });
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.title {
  margin-top: 15px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: rgb(153, 153, 153);
  background: #ebedf0;
  border-color: initial;
  border-image: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  line-height: 40px;
  text-indent: 10px;
}

.quit {
  display: flex;
  align-items: center;
  margin-top: 50px;
  .btn {
    width: 96%;
    border: 0;
    margin: 0 auto;
    font-size: 16px;
    height: 45px;
    line-height: 20px;
    border-radius: 7px;
    color: #fff;
    background-color: rgba(245, 108, 108, 0.8);
  }
}
</style>
